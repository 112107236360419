// client/src/components/Header.js

import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import logo from '../assets/appicon.png'

function Header() {
    
    const [buttonClicked, setButtonClicked] = useState(false);
    const handleClick = () => {
        setButtonClicked(!buttonClicked);
    };
    
    const [isSticky, setIsSticky] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 200) { // Adjust the scroll threshold as needed
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [isMenuVisible, setMenuVisible] = useState(false);
    const toggleMenu = () => {
        setMenuVisible(!isMenuVisible);
      };
    
    return (
        <header>        
            <div id="sticky-header" className={`menu-area transparent-header ${isSticky ? 'sticky-menu' : ''}`}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>

                            <div className="mobile-nav-toggler" onClick={toggleMenu}><i className="fs-1 bi bi-list"/></div>

                            <div className={`menu-wrap ${isMenuVisible ? 'mobile-menu-visible' : ''}`}>
                                <nav className="menu-nav">
                                    <div className='logo'>
                                        <a href="/#" style={{cursor: 'none' }}><img src={logo} alt="Logo" style={{ width: '80px', height: 'auto', maxWidth: '100%' }}/></a>
                                    </div>
                                    <div className='navbar-wrap main-menu d-none d-lg-flex'>
                                        <ul className='navigation'>
                                            <li className="active">
                                                <Link to="/" title="Startseite" style={{cursor: 'none'}}>Home</Link>
                                            </li>
                                            <li className="">
                                                <Link to="/downloads" title="Downloads" style={{cursor: 'none'}}>Downloads</Link> 
                                            </li>
                                            <li className="">
                                                <Link to="/blog" title="Blog" style={{cursor: 'none'}}>News</Link> 
                                            </li>
                                        </ul>
                                    </div> 
                                </nav>

                                <div className="mobile-menu">
                                    <div className='menu-box'>
                                        <div className="close-btn" onClick={toggleMenu}><i className="bi bi-x-lg"/></div>
                                        <div className="nav-logo"><a href="/" style={{cursor: 'none'}}><img src={logo} alt="Logo"/></a></div>
                                        <div className='menu-outer'>
                                            <ul className="navigation">
                                                <li className="active"><Link to="/" title="Startseite" style={{cursor: 'none'}}>Home</Link></li>
                                                <li className=""><Link to="/downloads" title="Downloads" style={{cursor: 'none'}}>Downloads</Link> </li>
                                                <li className=""><Link to="/blog" title="Blog" style={{cursor: 'none'}}>News</Link></li>
                                            </ul>
                                        </div>
                                        <div className="social-links">
                                            <ul className="clearfix list-wrap">
                                                <li><a href="https://www.linkedin.com/in/arne-schunter" style={{cursor: 'none'}}><i className="bi bi-linkedin"/></a></li>
                                                <li><a href="https://github.com/nbungu" style={{cursor: 'none'}}><i className="bi bi-github"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='menu-backdrop'></div>

                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </header>
)}

export default Header

/**
Inside 'menu-nav' Node
<div className='header-action'>
    <ul className="list-wrap">
        <li className="header-btn"><a href="#!" className="btn" onClick={handleClick} style={{cursor: 'none'}}>Contact<span></span></a></li>
    </ul>
</div>


<div className={`header-contact-wrap ${buttonClicked ? 'active' : ''}`}>
    <div className="container">
        <div className="row">
            <div className="col-lg-8">
                <div className="left-side-content">
                    <h3 className="title">Contact</h3>
                    <div className="row">
                        <div className="col-xl-4 col-lg-5 col-md-6">
                            <div className="contact-info-list">
                                <ul className="list-wrap">
                                    <li>
                                        <div className="icon"></div>
                                        <div className="content"><a href="mailto:company@gmail.com" style={{cursor: 'none'}}>company@gmail.com</a></div>
                                    </li>
                                    <li>
                                        <div className="icon"></div>
                                        <div className="content"><span>Ranelagh Place, Liverpool, L3 5UL, England</span></div>
                                    </li>
                                    <li>
                                        <div className="icon"></div>
                                        <div className="content"><span>7 Days a week <br/>from 10-00 am to 6-00 pm</span></div>
                                    </li>
                                </ul>
                                <div className="lats-chat"><a href="/contact" style={{cursor: 'none'}}>Let’s Chat</a></div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-7 col-md-6">
                            <div className="our-best-project">
                                <div className="content">
                                    <h4 className="title">One of Our <span>Best Ongoing</span> Projects</h4>
                                    <a className="btn" href="/project-details" style={{cursor: 'none'}}>Details <span></span></a>
                                </div>
                                <div className="thumb"><img src={ourProjectImg} alt=""/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="customer-support-wrap">
                    <h4 className="title">Customer Support</h4>
                    <div className="customer-support-content">
                        <div className="content-top"><img src={supportImg} alt=""/>
                            <h4 className="title">Need Help Choosing a Plan?</h4>
                        </div>
                    </div>
                    <p>We offer solutions for businesses of all sizes. For questions about our plans and products, contact our team of experts. <a href="/contact" style={{cursor: 'none'}}>Get in touch</a></p>
                </div>
                <div className="content-bottom">
                    <a href="/contact" style={{cursor: 'none'}}>Support</a>
                    <a href="/contact" style={{cursor: 'none'}}>Documentation</a>
                </div>
            </div>
        </div>                  
    </div>
</div>
<div className={`body-contact-overlay ${buttonClicked ? 'active' : ''}`}></div>

 */