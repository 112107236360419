// client/src/components/BannerArea.js

import React, { useState } from 'react';
import { STRAPI_CMS_URL } from '../utils/Utils.js';
import { useBannerArea } from "../utils/fetchContent.js";
import LoadingSpinner from './LoadingSpinner.js';

function BannerArea() {

    const pageContent = useBannerArea();
    const [hoveredPoint, setHoveredPoint] = useState(null);
  
    const hoverPoints = pageContent && pageContent.attributes ?[
      { id: 1, top: '23%', left: '75%', image: STRAPI_CMS_URL + pageContent.attributes.featureImage1.data.attributes.url },
      { id: 2, top: '20%', left: '30%', image: STRAPI_CMS_URL + pageContent.attributes.featureImage2.data.attributes.url },
      { id: 3, top: '55%', left: '55%', image: STRAPI_CMS_URL + pageContent.attributes.featureImage3.data.attributes.url }
    ] : [];
    
    return (    
        <section className="banner-bg">
        {!pageContent ? (<LoadingSpinner message={"Lade Banner..."} />) : (
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="banner-img">
                <img src={STRAPI_CMS_URL + pageContent.attributes.image.data.attributes.url} alt="" className="main-image"/>
                {hoverPoints.map(point => (
                  <div
                    key={point.id}
                    className="hover-point"
                    style={{ top: point.top, left: point.left }}
                    onMouseEnter={() => {setHoveredPoint(point.id)}}
                    onMouseLeave={() => {setHoveredPoint(null)}}>
                    {hoveredPoint === point.id && (
                      <img src={point.image} alt={`Feature ${point.id}`} className="popup-image" />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-content">
                <span className="sub-title">Einfaches <strong>Baupyhsik</strong> Tool</span>
                <h2 className="title">{pageContent.attributes.title}</h2>
                <a className="btn" href={STRAPI_CMS_URL + pageContent.attributes.download?.data?.attributes?.file.data.attributes.url} download={pageContent.attributes.download.data.attributes.fileName} style={{cursor: "none"}}><i className="fs-5 bi bi-download pe-3"/>Download<span></span></a>
                <p className='py-2'>Version {pageContent.attributes.download.data.attributes.semanticVersion} | {pageContent.attributes.download.data.attributes.platform}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
)}

export default BannerArea

/*

<section className="banner-area banner-bg">
            {!pageContent ? <LoadingSpinner message={"Lade Banner..."}/> : 
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="banner-img">
                            <img src={STRAPI_CMS_URL + pageContent.attributes.image.data.attributes.url} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="banner-content">
                            <span className="sub-title">Einfaches <strong>Baupyhsik</strong> Tool</span>
                            <h2 className="title">{pageContent.attributes.title}</h2>
                            <a className="btn" href={STRAPI_CMS_URL + pageContent.attributes.download?.data?.attributes?.file.data.attributes.url} download={pageContent.attributes.download.data.attributes.fileName} style={{cursor: "none"}}><i className="fs-5 bi bi-download pe-3"/>Download<span></span></a>
                            <p className='py-2'>Version {pageContent.attributes.download.data.attributes.semanticVersion} | {pageContent.attributes.download.data.attributes.platform}</p>
                        </div>
                    </div>
                </div>
            </div>}
        </section>


*/