// client/src/pages/Blog.js

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { STRAPI_CMS_URL } from '../utils/Utils.js';
import { usePosts } from '../utils/fetchContent.js';
import LoadingSpinner from '../components/LoadingSpinner.js';

import Header from '../components/Header.js';
import Footer from '../components/Footer.js';

function Blog() {

    const { posts } = usePosts();

    useEffect(() => {
    document.title = "Blog > BauphysikTool";
    }, []);

    return (
        <main>
            <Header/>            
            <section className="breadcrumb-area breadcrumb-area-three parallax pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-content">
                                <h2 className="title">Blog</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/" title="Startseite" style={{cursor: 'none'}}>Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Blog</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="inner-blog-area pb-120">
                {!posts ? <LoadingSpinner message={"Lade Posts..."}/> : 
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-70">
                            {posts.map((post, index) => (
                            <div className="inner-blog-item">
                                <div className="inner-blog-thumb">
                                    <Link to={`/posts/${post.id}`} style={{cursor: 'none' }} key={post.attributes.title + index}>
                                        {!post.attributes.titleImage.data ? <img alt=''/> : <img src={STRAPI_CMS_URL + post.attributes.titleImage.data.attributes.url} alt=""/>}
                                    </Link>
                                </div>
                                <div className="inner-blog-content">
                                    <div className="blog-meta-two">
                                        <ul className="list-wrap">
                                            <li className="tag"><a href="/blog" style={{cursor: 'none' }}>{post.attributes.category}</a></li>
                                            <li><i className="bi bi-clock"></i>{post.attributes.readTime}</li>
                                            <li><i className="bi bi-calendar-week"></i>{post.attributes.date}</li>
                                            <li>By {post.attributes.author}</li>
                                        </ul>
                                    </div>
                                    <Link to={`/posts/${post.id}`} style={{cursor: 'none' }} key={post.attributes.title + index}>
                                        <h2 className="title">{post.attributes.title}</h2>
                                    </Link>                                    
                                    <p>{post.attributes.caption}</p>
                                    <Link className="read-more-btn" to={`/posts/${post.id}`} style={{cursor: 'none' }} key={post.attributes.title + index}>
                                        Read More
                                    </Link>
                                </div>
                            </div>))}
                            
                        </div>
                        <div className="col-30">
                            <aside className="blog-sidebar">
                                <div className="widget">
                                    <div className="rc-post-wrap">
                                        {posts.map((post, index) => (
                                        <div className="rc-post-item">
                                            <div className="thumb">
                                            <Link to={`/posts/${post.id}`} style={{cursor: 'none' }} key={post.attributes.title + index}>
                                                {!post.attributes.titleImage.data ? <img alt=''/> : <img src={STRAPI_CMS_URL + post.attributes.titleImage.data.attributes.url} alt=""/>}
                                            </Link>
                                            </div>
                                            <div className="content">
                                                <div className="blog-meta-two">
                                                    <ul className="list-wrap">
                                                        <li className="tag"><a href="/blog" style={{cursor: 'none' }}>{post.attributes.category}</a></li>
                                                        <li><i className="bi bi-clock"></i>{post.attributes.readTime}</li>
                                                    </ul>
                                                </div>
                                                <Link to={`/posts/${post.id}`} style={{cursor: 'none' }} key={post.attributes.title + index}>
                                                    <h4 className="title">{post.attributes.title}</h4>
                                                </Link>
                                            </div>
                                        </div>))}
                                    </div>
                                </div>
                                <div className="widget">
                                    <h2 className="widget-title">Categories</h2>
                                    <div className="blog-cat-list">
                                        <ul className="list-wrap">
                                            <li><a href="#" style={{cursor: 'none' }}>News <span>(0)</span></a></li>
                                            <li><a href="#" style={{cursor: 'none' }}>Release <span>(1)</span></a></li>
                                            <li><a href="#" style={{cursor: 'none' }}>Bugfix <span>(0)</span></a></li>
                                            <li><a href="#" style={{cursor: 'none' }}>Update <span>(0)</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                                
                            </aside>
                        </div>
                    </div>
                </div>}
            </section>

            <Footer/>
        </main>
    )
}

export default Blog

/*

<div className="pagination-wrap">
    <nav aria-label="Page navigation example">
        <ul className="pagination list-wrap">
            <li className="page-item"><a className="page-link" href="#" style={{cursor: 'none' }}>1</a></li>
            <li className="page-item active"><a className="page-link" href="#" style={{cursor: 'none' }}>2</a></li>
            <li className="page-item"><a className="page-link" href="#" style={{cursor: 'none' }}>3</a></li>
            <li className="page-item"><a className="page-link" href="#" style={{cursor: 'none' }}>4</a></li>
        </ul>
    </nav>
</div>

*/