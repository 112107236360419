import React from "react";
import ReactDOM from 'react-dom/client';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { disableReactDevTools } from '@fvilers/disable-react-devtools'; 

// Styling - npm packages
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'animate.css/animate.min.css';
// Styling - custom rules
import './index.css';
import './style.css';
import './responsive.css';
import './default.css';

// Components
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import BlogPost from "./pages/BlogPost";
import Impressum from "./pages/Impressum";
import Blog from "./pages/Blog";
import Downloads from "./pages/Downloads";

if (process.env.NODE_ENV === 'production') disableReactDevTools();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// <App/> is a React component and used as Starting Page (App.js)
export default function App() {
  
  /*
  React Router is meant for internal navigation (client-side-routing) within your application
  and can only redirect to internal routes.

  Trying to access a dynamic route like /posts/postTitleXY, directly from the browser (server-side-routing) doesnt work.
  -> Implement SSR (server-side-routing)
          
  */ 

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path='/blog' element={<Blog/>}/>
        <Route path='/posts/:id' element={<BlogPost/>}/>
        <Route path='/impressum' element={<Impressum/>}/>
        <Route path='/downloads' element={<Downloads/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </BrowserRouter>
  );
}
