// client/src/pages/NotFound.js

import React, { useEffect } from "react";
import Footer from '../components/Footer';
import { PUBLIC_URL } from '../utils/Utils.js';

function NotFound() {
  
  // We want updateMetaTags() to be executed everytime App component loads
  useEffect(() => {
    document.title = "Seite nicht gefunden :(";
  }, []);

  return (
    <main>
      <section className="banner-bg">
        <div className="container d-flex w-100 h-100 p-3 mx-auto my-auto flex-column">
          <div className="px-3 text-center">
            <h1>Diese Seite konnte nicht gefunden werden!</h1>
            <p>Keine Sorge, hier gehts weiter zur Startseite des Baupyhsik Tools...</p>
            <p>
              {/* Use <a> instead of <Link> to avoid CSP blocking traffic to strapiCMS */}
              <a href={PUBLIC_URL} className="btn mt-5">Zurück zur Homepage<span></span></a>
            </p>
          </div>
        </div>
        <Footer/>  
      </section>    
    </main>
  )
}

export default NotFound

/*

TODO:

After visiting This Page, all Strapi DB requests get blocked by CSP!

Sometimes between switching News Posts 404Page comes up briefly but then interrupts following DB requests.

FIX DONE!

*/