// client/src/components/ServicesArea.js
import React from 'react';
import { STRAPI_CMS_URL } from '../utils/Utils.js';
import { useServices } from '../utils/fetchContent';
import LoadingSpinner from './LoadingSpinner.js';

function ServicesArea() {
    
    const services = useServices();

    return (    
        <section className="inner-services-area pb-100">
            {!services ? <LoadingSpinner message={"Lade Services..."}/> : 
            <div className="container">
                <div className="inner-services-item-wrap">
                    <div className="row justify-content-center">
                        {services.map((service, index) => (
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8" key={index}>
                            <div className="services-item-four">
                                <div className="services-icon-four">
                                {!service.attributes.icon.data ? <div/> : <img src={STRAPI_CMS_URL + service.attributes.icon.data.attributes.url} alt=""/>}
                                </div>
                                <div className="services-content-four">
                                    <h2 className="title"><a href="/services-details" style={{cursor: 'none' }}>{service.attributes.title}</a></h2>
                                    <p>{service.attributes.caption}</p>
                                </div>
                            </div>
                        </div>))}
                    </div>
                </div>
            </div>}
        </section>        
)}

export default ServicesArea

/**
        <section className="services-area pt-35 pb-95">
            {!services ? <LoadingSpinner message={"Lade Services..."}/> : 
            <div className="container">
                <div className="row justify-content-center">
                {services.map((service, index) => (
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
                        <div className="services-item">
                            <div className="services-icon">
                                {!service.attributes.image.data ? <div/> : <img src={STRAPI_CMS_URL + service.attributes.image.data.attributes.url} alt=""/>}
                            </div>
                            <div className="services-content">
                                <h4 className="title"><a href="/services-details" style={{cursor: 'none' }}>{service.attributes.title}</a></h4>
                                <p>{service.attributes.caption}</p>
                            </div>
                        </div>
                    </div>))}
                </div>
            </div>}
        </section>



<section className="inner-services-area pt-110 pb-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="section-title title-style-two text-center mb-60">
                            <span className="sub-title">Graphic Service</span>
                            <h2 className="title">Creative &amp; Branding Design</h2>
                        </div>
                    </div>
                </div>
                <div className="inner-services-item-wrap">
                    <div className="row justify-content-center">
                        {services.map((service, index) => (
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                            <div className="services-item-four">
                                <div className="services-icon-four">
                                    <img src="/img/icon/inner_services_icon01.png" alt=""/>
                                </div>
                                <div className="services-content-four">
                                    <h2 className="title"><a href="/services-details" style="cursor: none;">Advertising</a></h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                        </div>))}
                    </div>
                </div>
            </div>
        </section>

 */

