// client/src/pages/Downloads.js

import React, { useEffect } from "react";
import { PUBLIC_URL } from '../utils/Utils.js';
import { STRAPI_CMS_URL } from '../utils/Utils.js';
import { useDownloads } from "../utils/fetchContent.js";

import Footer from '../components/Footer.js';
import DownloadListItem from "../components/DownloadListItem.js";
import LoadingSpinner from "../components/LoadingSpinner.js";

function Downloads() {
  
  const { downloads } = useDownloads();

  // We want updateMetaTags() to be executed everytime App component loads
  useEffect(() => {
    document.title = "Versionsübersicht > BauphysikTool";
  }, []);

  return (
    <main>
      <section className="banner-bg">
        <div className="container d-flex w-100 h-100 p-3 mx-auto my-auto flex-column">
        {!downloads ? <LoadingSpinner message={"Lade Downloads..."}/> :
          <div className="px-3 text-left">            
            <h1>Versionsübersicht</h1>
            <h3 className="mt-5">Neueste Version</h3>
            <div className="download-list">              
              <DownloadListItem
                key={0}
                semanticVersion={downloads[0].attributes.versionTag}
                subtitle={downloads[0].attributes.subtitle}
                platform={downloads[0].attributes.platform}
                downloadLink={STRAPI_CMS_URL + downloads[0].attributes.file.data.attributes.url}
                fileName={downloads[0].attributes.file.data.attributes.name}
                isHighlighted={true}
              />
            </div>
            <h3 className="mt-5">Ältere Versionen</h3>
            <div className="download-list">
              {downloads.slice(1).map((item, index) => (
                <DownloadListItem
                  key={index + 1}
                  semanticVersion={item.attributes.semanticVersion}
                  subtitle={item.attributes.subtitle}
                  platform={item.attributes.platform}
                  downloadLink={STRAPI_CMS_URL + item.attributes.file.data.attributes.url}
                  fileName={item.attributes.file.data.attributes.name}
                  isHighlighted={false}
                />
              ))}
            </div>
            <p>
              {/* Use <a> instead of <Link> to avoid CSP blocking traffic to strapiCMS */}
              <a href={PUBLIC_URL} className="btn mt-5">
                Zurück zur Homepage<span></span>
              </a>
            </p>
          </div>}
        </div>
        <Footer />
      </section>
    </main>
  );
}

export default Downloads

/*

TODO:

After visiting This Page, all Strapi DB requests get blocked by CSP!

Sometimes between switching News Posts 404Page comes up briefly but then interrupts following DB requests.

FIX DONE!

*/