// client/src/pages/Home.js

// TODO: https://xolio-react.netlify.app/?storefront=envato-elements

import React, { useEffect } from "react";

import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import BannerArea from "../components/BannerArea.js";
import ServicesArea from "../components/ServicesArea.js";
import AboutArea from "../components/AboutArea.js";
import BlogArea from "../components/BlogArea.js";
import DonationButton from "../components/DonationButton.js";

function Home() {

  useEffect(() => {
    document.title = "Startseite > BauphysikTool";
  }, []);

  return (
    <main>
      <Header/>
      <BannerArea/>
      <ServicesArea/>
      <AboutArea/>
      <BlogArea/>
      <Footer/>
      <DonationButton/>
    </main>
  )
}

export default Home