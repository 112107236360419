// client/src/components/DownloadListItem.js

import React from 'react';

function DownloadListItem({ semanticVersion, subtitle, platform, downloadLink, fileName, isHighlighted }) {
    return (
        <div className={`download-list-item d-flex align-items-center mb-4 ${isHighlighted ? 'highlighted' : ''}`}>
          <div className="icon me-3">
            <i className="bi bi-file-earmark-arrow-down"></i>
          </div>
          <div className="text flex-grow-1">
            <div className="d-flex align-items-center">
              <h5 className="mb-1">{semanticVersion}</h5>
              <span className="platform-tag ms-2">{platform}</span>
            </div>
            <p className="mb-1 subtitle-text">{subtitle}</p>
          </div>
          <div className="download-button">
            <a href={downloadLink} className="btn btn-sm btn-primary" download={fileName}>
              Download
            </a>
          </div>
        </div>
      );
  }
  
  export default DownloadListItem;