// client/src/components/AboutArea.js

import React from 'react';
import { STRAPI_CMS_URL } from '../utils/Utils.js';
import { useAboutArea } from '../utils/fetchContent';
import LoadingSpinner from './LoadingSpinner.js';

function AboutArea() {
    
    const pageContent = useAboutArea();
    
    return (    
        <section className="about-area">    
            {!pageContent ? <LoadingSpinner message={"Lade About..."}/> : 
            <div className="container custom-container">
                <div className="about-inner">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-46 order-0 order-lg-2">
                            <div className="about-img text-end">
                                {!pageContent.attributes.image.data ? <div/> : <img src={STRAPI_CMS_URL + pageContent.attributes.image.data.attributes.url} alt=""/>}
                            </div>
                        </div>
                        <div className="col-54">
                            <div className="about-content">
                                <div className="section-title mb-3">
                                    <span className="sub-title">{pageContent.attributes.subTitle}</span>
                                    <h2 className="title">{pageContent.attributes.title}</h2>
                                </div>
                                <p>{pageContent.attributes.content}</p>
                                <ul className="list-wrap">
                                    <li>
                                        
                                        <div className="icon">
                                            {!pageContent.attributes.entry1Icon.data ? <div/> : <img src={STRAPI_CMS_URL + pageContent.attributes.entry1Icon.data.attributes.url} alt=""/>}
                                        </div>
                                        <div className="content">
                                            <h4 className="title">{pageContent.attributes.entry1Title}</h4>
                                            <p>{pageContent.attributes.entry1Content}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            {!pageContent.attributes.entry2Icon.data ? <div/> : <img src={STRAPI_CMS_URL + pageContent.attributes.entry2Icon.data.attributes.url} alt=""/>}
                                        </div>
                                        <div className="content">
                                            <h4 className="title">{pageContent.attributes.entry2Title}</h4>
                                            <p>{pageContent.attributes.entry2Content}</p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="about-content-bottom">
                                    <span>Bugs oder Rechenfehler entdeckt? Auf GitHub beitragen!</span>
                                    <div className="read-more-btn">
                                        <a className="btn" href="https://github.com/nbungu/BauphysikToolWPF" style={{cursor: 'none'}}><i className="fs-5 bi bi-github pe-3"/>GitHub Repo<span></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </section>
)}

export default AboutArea