// client/src/components/DonationButton.js

import React from 'react';

import donationImage from '../assets/donate-to-bauphysik-tool.png';

function DonationButton() {
  return (
    <div className="donation-button-container">
      <img src={donationImage} alt="Donate" className="donation-image" />
      <div className="donation-content">        
        <p className="donation-text">Unterstütze die Entwicklung!</p>
        <a href="https://www.paypal.com/donate/?hosted_button_id=ZCHH4QMYBXEB6" className="donation-btn">
        <i className="bi bi-paypal"/>Spenden 
        </a>
      </div>
      <p className="donation-text-sm">via PayPal Link</p>
    </div>
  );
}

export default DonationButton;