// client/src/components/BlogArea.js

import React from 'react';
import { STRAPI_CMS_URL } from '../utils/Utils.js';
import { usePosts } from '../utils/fetchContent';
import { Link } from "react-router-dom";
import LoadingSpinner from './LoadingSpinner.js';

import defaultAvatar from '../assets/blog_avatar.png';

function BlogArea() {
    
    const { posts } = usePosts();

    return (   
        <section className="blog-area pt-110 pb-120">
            {!posts ? <LoadingSpinner message={"Lade Posts..."}/> : 
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="section-title text-center mb-5">
                            <span className="sub-title">My Blog</span>
                            <h2 className="title">News &amp; Updates</h2>
                            <p>Beiträge rund um das Thema Baupyhsik, Infos zu neuen Programmfeatures und Anleitungen zur Bedienung.</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {posts.map((post, index) => (
                    <Link className="col-lg-4 col-md-6" to={`/posts/${post.id}`} key={post.attributes.title + index}>
                        <div className="blog-post-item">
                            <div className="blog-post-thumb">
                                <a href={`/posts/${post.id}`} style={{cursor: "none"}}>{!post.attributes.titleImage.data ? <img alt=''/> : <img src={STRAPI_CMS_URL + post.attributes.titleImage.data.attributes.url} alt=""/>}</a>
                            </div>
                            <div className="blog-post-content">
                                <a className="tag" href="/blog" style={{cursor: "none"}}>{post.attributes.category}</a>
                                <h2 className="title"><a href={`/posts/${post.id}`} style={{cursor: "none"}}>{post.attributes.title}</a></h2>
                                <div className="blog-meta">
                                    <ul className="list-wrap">
                                        <li className="avatar-img"><a href="/blog" style={{cursor: "none"}}>{!post.attributes.authorAvatar.data ? <img src={defaultAvatar} alt=''/> : <img src={STRAPI_CMS_URL + post.attributes.authorAvatar.data.attributes.url} alt=""/>}</a></li>
                                        <li>By <a href="/blog" style={{cursor: "none"}}>{post.attributes.author}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Link>))}
                </div>
            </div>}
        </section>
)}

export default BlogArea